/* index.css or styles.css */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

body {
  font-family: Arial, sans-serif;
}

* {
  box-sizing: border-box;
}
